import jwtDecode from 'jwt-decode';
import queryString from 'query-string';
import { EventEmitter } from 'events';
import { bankIds } from './instantorBankid';
import {
  isValidAmortizationPeriod,
  isValidAmount,
  isValidSSN,
} from './validationUtils';
import { Client } from 'urql';
import {
  GetAuthForSsnStatusDocument,
  GetAuthForSsnStatusQuery,
  GetAuthForSsnStatusQueryVariables,
  InitAuthForSsnDocument,
  InitAuthForSsnMutation,
  InitAuthForSsnMutationVariables,
} from '../../graphql/generated';

export const isSomeEnum = <T extends { [s: string]: unknown }>(
  e: T,
  token: unknown,
): token is T[keyof T] => Object.values(e).includes(token as T[keyof T]);

export function formatMoney(value) {
  return String(Math.round(value)).replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0');
}

export function formatMoneyText(value) {
  return formatMoney(value) + ' kr';
}

export function formatSsnText(value) {
  return value.slice(0, 8) + '-' + value.slice(8);
}

export function formatAmortizationPeriodText(months) {
  return `${months} månader`;
}

export function roundUpToNearest(value, roundTo) {
  return Math.ceil(value / roundTo) * roundTo;
}

export function roundDownToNearest(value, roundTo) {
  return Math.floor(value / roundTo) * roundTo;
}

export function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

export function extractInteger(value) {
  return parseInt(String(value).replace(/\D/g, ''), 10);
}

export const stripNonDigits = value => value.replace(/\D/g, '');

export const getDaysInMonth = date =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

export function sliding(xs, size) {
  const numberOfPartitions = xs.length / size;
  const partitionIndexes = Array.from(Array(numberOfPartitions).keys());
  return partitionIndexes.map(i => xs.slice(i * size, i * size + size));
}

export function repeat(str, len) {
  let result = '';

  for (let i = 0; i < len; ++i) {
    result += str;
  }

  return result;
}

export function padStart(str, len, ch = ' ') {
  return pad(str, len, ch) + str;
}

export function pad(str, len, ch) {
  str = String(str);
  if (str.length >= len) {
    return '';
  }
  return repeat(ch, len - str.length);
}

export function prependCentury(str: string) {
  const strippedSsn = str.replaceAll(/\D/g, '');
  if (!strippedSsn || strippedSsn.length !== 10) {
    return str;
  }

  const twoDigitYear = parseInt(strippedSsn.slice(0, 2), 10);
  const currentYear = new Date().getFullYear();
  const currentCentury = Math.floor(currentYear / 100);
  const prevCentury = currentCentury - 1;
  const isProbablyThisCentury = twoDigitYear <= currentYear % 100;

  const century = isProbablyThisCentury ? currentCentury : prevCentury;

  const newSsn = padStart(century, 2, '0') + str;
  if (isValidSSN(newSsn)) {
    return newSsn;
  }
  return str;
}

export function isSupportedByInstantor(bankName) {
  return Object.keys(bankIds).indexOf(bankName) !== -1;
}

function getValidatedFormFields(amountString, yearsString) {
  const amount = Number(amountString);
  const years = Number(yearsString);
  const amortizationPeriod = years && Math.max(years * 12, 36);
  return {
    amount: (isValidAmount(amount) && amount) || undefined,
    amortizationPeriod:
      (isValidAmortizationPeriod(amortizationPeriod) && amortizationPeriod) ||
      undefined,
  };
}

// form field params are passed in url in format https://bynk.se/utoka/$amount/$years
export function parseFormfieldsForUtoka(url) {
  const matches = /utoka\/(\d+)\/(\d+)/.exec(url);
  if (matches && matches.length === 3) {
    const [, amountString, yearsString] = matches;
    return getValidatedFormFields(amountString, yearsString);
  }

  return {}; // No parameters passed in url
}

export function parseFormfieldsFromSearchString(searchString) {
  const parsed = queryString.parse(searchString);
  if (parsed.amount || parsed.years) {
    return getValidatedFormFields(parsed.amount, parsed.years);
  }
  return {}; // No parameters passed in searchString
}

export function formatInterestRate(interestRate = 0) {
  return interestRate.toFixed(2).replace('.', ',');
}

export const exampleInterestRate = _amount => {
  return 9.97;
  /*
  if (_amount > 80000) return 8.95;
  if (_amount > 55000) return 9.95;
  if (_amount > 25000) return 10.95;
  else return 11.95;
  */
};

export function callOnce(fn) {
  let called = false;

  return () => {
    if (called) return;
    called = true;
    fn();
  };
}

export function isMobile() {
  return window && Boolean(window.navigator.userAgent.match(/mobi/i));
}

export function isiOS() {
  return window && /(iPad|iPhone|iPod)/g.test(window.navigator.userAgent);
}

export function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1);
}

export const eventPromise = (
  eventEmitter: EventEmitter,
  event: string,
  timeoutMs: number = 15000,
) =>
  new Promise<any>((resolve, reject) => {
    const timeoutRejection = setTimeout(
      () =>
        reject(
          Error(
            `A timeout occurred after waiting ${timeoutMs} ms for ${event}`,
          ),
        ),
      timeoutMs,
    );
    eventEmitter.once(event, result => {
      clearTimeout(timeoutRejection);
      resolve(result);
    });
  });

export const removeEmpty = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
    else if (obj[key] === undefined || obj[key] === null) delete obj[key];
  });
  return obj;
};

export function formatDateText(date) {
  return date.toISOString().substr(0, 10);
}

export const formatPostalAddress = x => `${x.zip} ${x.city}`;

export const secondsSinceEpoch = date => Math.floor(date / 1000);

export const tokenIsExpired = (token: string) => {
  try {
    const decoded: any = jwtDecode(token);
    return decoded?.exp ? secondsSinceEpoch(Date.now()) > decoded.exp : true;
  } catch (e) {
    return true;
  }
};

export const delayPromise = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const convertContentfulFeaturesToCards = featureList => {
  const convertToCard = f => {
    return {
      icons: [f.icon],
      subtitle: f.title,
      description: f.childContentfulFeatureDescriptionTextNode
        ? f.childContentfulFeatureDescriptionTextNode.description
        : f.description.description,
    };
  };
  return featureList.map(convertToCard);
};

// https://gist.github.com/kucukharf/677d8f21660efaa33f72
export const RATE = (
  periods,
  payment,
  present,
  future,
  type,
  guess = undefined,
) => {
  guess = guess === undefined ? 0.01 : guess;
  future = future === undefined ? 0 : future;
  type = type === undefined ? 0 : type;

  // Set maximum epsilon for end of iteration
  const epsMax = 1e-10;

  // Set maximum number of iterations
  const iterMax = 10;

  // Implement Newton's method
  let y,
    y0,
    y1,
    x0,
    x1 = 0,
    f = 0,
    i = 0;

  let rate = guess;
  if (Math.abs(rate) < epsMax) {
    y =
      present * (1 + periods * rate) +
      payment * (1 + rate * type) * periods +
      future;
  } else {
    f = Math.exp(periods * Math.log(1 + rate));
    y = present * f + payment * (1 / rate + type) * (f - 1) + future;
  }
  y0 = present + payment * periods + future;
  y1 = present * f + payment * (1 / rate + type) * (f - 1) + future;
  i = x0 = 0;
  x1 = rate;
  while (Math.abs(y0 - y1) > epsMax && i < iterMax) {
    rate = (y1 * x0 - y0 * x1) / (y1 - y0);
    x0 = x1;
    x1 = rate;
    if (Math.abs(rate) < epsMax) {
      y =
        present * (1 + periods * rate) +
        payment * (1 + rate * type) * periods +
        future;
    } else {
      f = Math.exp(periods * Math.log(1 + rate));
      y = present * f + payment * (1 / rate + type) * (f - 1) + future;
    }
    y0 = y1;
    y1 = y;
    ++i;
  }
  return rate;
};

export const FV = (rate, nper, pmt, pv, type) => {
  const pow = Math.pow(1 + rate, nper);
  let fv = 0;
  if (rate) {
    fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow;
  } else {
    fv = -1 * (pv + pmt * nper);
  }

  return fv;
};

export const calculateLoanPreview = (
  loanMonths: number,
  carPrice: number,
  deposit: number,
  interest: number,
  salvagePercentage: number,
  startupFee: number,
  administrationFee: number,
) => {
  const loanAmount = carPrice - deposit;
  const restValue = carPrice * salvagePercentage;

  // Calculate monthly cost
  const s = carPrice - deposit - carPrice * salvagePercentage;
  const p = interest / 12;
  const n = loanMonths;
  const monthlyCost = Math.round(
    s * (p / (1 - Math.pow(1 + p, -n))) + carPrice * salvagePercentage * p,
  );

  // Calculate other values
  const firstInstallment = monthlyCost + administrationFee;
  const ff2 = -(loanAmount - startupFee);
  const r1 = RATE(loanMonths, firstInstallment, ff2, restValue, 0) * 12;
  const effectiveInterestRate = (FV(r1 / 12, 12, 0, -100, 0) - 100) / 100;

  const totalToPayback = firstInstallment * loanMonths + startupFee + restValue;

  return {
    loanAmount,
    restValue,
    firstInstallment,
    effectiveInterestRate,
    totalToPayback,
    interest,
    monthlyCost,
  };
};

export const encodeSHA256 = async message => {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

/**
 * formats number to percentage: 0.01 => 1.00%
 *  0.01, showDecimals = false => 1%
 * @param num number
 * @param showDecimals boolean
 * @returns string
 */
export const numberToPercentageString = (
  num: number,
  showDecimals: boolean = true,
): string => {
  let res = `${+(num * 100).toFixed(2)}`.replace('.', ',');
  let splits = res.split(',');
  if (!showDecimals) {
    return splits[0] + ' %';
  }
  if (splits.length === 1) {
    return res + ',00 %';
  }

  let l = 2 - splits[1].length;
  while (l-- > 0) {
    res += '0';
  }

  return res + ' %';
};

export const isExternalLink = link => {
  return (
    link &&
    (link.startsWith('http') ||
      link.startsWith('//') ||
      link.startsWith('tel:') ||
      link.startsWith('mailto:'))
  );
};

export const formatDateString = (dateString: string) => {
  if (!dateString) return '';
  const onlyNumbers = dateString.replace(/\D/g, '');
  const month = onlyNumbers.slice(0, 2);
  const year = onlyNumbers.slice(2, 6);

  if (!year) {
    return `${month}`;
  }

  return `${month}/${year}`;
};

export const loginWithSsn = async (
  urqlClient: Client,
  ssn: string,
  setLoggedIn: () => void,
): Promise<string | null> => {
  const authJwt = await fetchAuthJwt(urqlClient, ssn);
  if (authJwt) {
    let token = undefined;
    while (!token) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      token = await pollForJwt(urqlClient, authJwt);
    }
    localStorage.setItem('token', token);
    setLoggedIn();
    return token;
  }
  return null;
};

const fetchAuthJwt = async (
  urqlClient: Client,
  ssn: string,
): Promise<string | undefined> => {
  try {
    const res = await urqlClient
      .mutation<InitAuthForSsnMutation, InitAuthForSsnMutationVariables>(
        InitAuthForSsnDocument,
        { ssn },
      )
      .toPromise();
    return res.data?.postAuth.authJwt;
  } catch (e) {
    console.log(e);
  }
};

const pollForJwt = async (
  urqlClient: Client,
  authJwt: string,
): Promise<string | undefined> => {
  try {
    const res = await urqlClient
      .query<GetAuthForSsnStatusQuery, GetAuthForSsnStatusQueryVariables>(
        GetAuthForSsnStatusDocument,
        { authJwt },
      )
      .toPromise();
    return res.data?.getAuth.rockerJwt;
  } catch (e) {
    console.log(e);
  }
  return undefined;
};

export const playStoreUrl =
  'https://play.google.com/store/apps/details?id=com.rocker.app';
export const playStoreRating = 4.5;

// fallback values - the actual numbers are supplied by BFF
export const loanPredictionDefaultVersionDetails = {
  accuracy: 79.16,
  releaseDate: '2023-07-25',
};
