import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AppState {
  loggedIn: boolean;
  firstName: string;
  memberId: string | null;
}

const initialState: AppState = {
  loggedIn: typeof window === 'undefined' || !!localStorage.getItem('token'),
  firstName: '',
  memberId: null,
};

const reducers = {
  setLoggedIn: (
    state: Draft<AppState>,
    setLoggedInAction: PayloadAction<boolean>,
  ) => {
    state.loggedIn = setLoggedInAction.payload;
  },
  setFirstName: (
    state: Draft<AppState>,
    setFirstNameAction: PayloadAction<string>,
  ) => {
    state.firstName = setFirstNameAction.payload;
  },
  setMemberId: (
    state: Draft<AppState>,
    setMemberIdAction: PayloadAction<string>,
  ) => {
    state.memberId = setMemberIdAction.payload;
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers,
});

export default appSlice;
