import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import createStore from './src/redux/createStore';
import { getFirebase } from '@src/lib/firebase';
import { theme2022 } from '@rocker-ui/web';

getFirebase();

import { Shadows } from '@mui/material/styles/shadows';
import UrqlProvider from './UrqlProvider';

const theme = createTheme({
  ...theme2022,
  shadows: Array(25).fill('none') as Shadows,
});

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const { store, persistor } = createStore();
  if (typeof window === 'undefined') {
    return <Provider store={store}>{element}</Provider>;
  }

  return (
    <Provider store={store}>
      <UrqlProvider>
        <ThemeProvider theme={theme}>
          <PersistGate loading={null} persistor={persistor}>
            {element}
          </PersistGate>
        </ThemeProvider>
      </UrqlProvider>
    </Provider>
  );
};
