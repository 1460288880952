import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
//import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage();

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['api', 'app'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

let middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware];//, logger];
}

const store = configureStore({
  reducer: persistedReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});

export default () => ({
  store,
  persistor: persistStore(store),
});
