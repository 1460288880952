import {
  isValidEmail,
  isValidMonthlySalary,
  isValidOccupation,
  isValidPhoneNumber,
  isValidSSN,
} from '@lib/validationUtils';
import {
  generateUpdateReducers,
  generateValidateReducers,
} from '@src/redux/utils';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  accountCreditDetails: {
    creditLimit: {},
    address: {},
  },
  applicationState: null,
  showForm: false,
  ssn: '',
  email: '',
  phone: '',
  customerName: null,
  customerAddress: null,
  occupation: null,
  employmentLength: -1, // Will be interpreted as Unknown on server
  monthlySalary: null,
  accommodation: null,
  multipleChildren: false,
  numberOfChildren: 0,
  sharedHousehold: false,
  monthlyLivingCost: null,
  isLoading: false,
  isValid: {
    ssn: false,
    email: false,
    phone: false,
    occupation: false,
    monthlySalary: false,
    accommodation: false,
    renegotiateLoans: false,
    multipleChildren: true,
    numberOfChildren: true,
    sharedHousehold: true,
    monthlyLivingCost: false,
    employmentLength: false,
  },
};

const validators = {
  ssn: isValidSSN,
  email: isValidEmail,
  phone: isValidPhoneNumber,
  monthlySalary: isValidMonthlySalary,
  occupation: isValidOccupation,
  accommodation: x => Boolean(x),
  numberOfChildren: () => true,
};

const reducers = {
  ...generateUpdateReducers(initialState),
  ...generateValidateReducers(initialState, validators),
};

const accountCreditApplicationSlice = createSlice({
  name: 'accountCreditApplication',
  initialState,
  reducers,
});

export default accountCreditApplicationSlice;
