import { createSlice } from '@reduxjs/toolkit';
import { generateUpdateReducers } from '../utils';

const initialState = {
  applicationId: null,
  isConnected: false,
  token: null,
  bankId: {
    isAuthing: false,
    isSigning: false,
  },
  isLoading: false,
  loadingContent: null,
  loadingUUID: null,
  isResuming: false,
  error: null,
};

const reducers = {
  ...generateUpdateReducers(initialState),
  authStarted: state => {
    state.bankId.isAuthing = true;
  },
  authCompleted: state => {
    state.bankId.isAuthing = false;
  },
  signingStarted: state => {
    state.bankId.isSigning = true;
  },
  signingCompleted: state => {
    state.bankId.isSigning = false;
  },
  cancelBankId: state => {
    state.bankId.isAuthing = false;
    state.bankId.isSigning = false;
  },
  errorOccurred: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers,
});

export default apiSlice;
