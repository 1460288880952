import { prependCentury, stripNonDigits } from './utils';
//import { bankAccountValidationPicker } from './bankAccountvalidation'
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function isNumber(num) {
  // This function returns true for strings as well.
  return !isNaN(num) && isFinite(num);
}

function luhn(str) {
  const num = str
    .split('')
    .map(Number)
    .map((num, i) => (i % 2 === 0 ? num * 2 : num))
    .reduce((acc, num) => acc + Math.floor(num / 10) + (num % 10), 0);

  return (10 - (num % 10)) % 10;
}

const ssnToDateString = ssn =>
  `${ssn.slice(0, 4)}-${ssn.slice(4, 6)}-${ssn.slice(6, 8)}`;

function calculateAge(birthday) {
  // https://stackoverflow.com/questions/4060004/calculate-age-given-the-birth-date-in-the-format-yyyymmdd/21984136#21984136
  var ageDate = new Date(Date.now() - birthday.getTime());
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const isOlderThan = maxAge => ssn => {
  const birthdate = new Date(ssnToDateString(ssn));
  return calculateAge(birthdate) > maxAge;
};

export const isYoungerThan = minAge => ssn => !isOlderThan(minAge - 1)(ssn);
const ssnRegex = new RegExp('^(19|20)?[0-9]{6}[-]?[0-9]{4}$');

export function isValidSSNExtended(
  _ssn: string,
  skipAgeCheck: boolean,
): boolean {
  if (!ssnRegex.test(_ssn)) return false;

  const ssn = prependCentury(_ssn.replaceAll(/\D/g, ''));
  const lastNumber = Number(ssn.slice(-1));

  if (!skipAgeCheck) {
    if (!isOlderThan(19)(ssn)) return false;
    if (isOlderThan(70)(ssn)) return false;
  }
  return luhn(ssn.slice(-10, -1)) === lastNumber;
}

export function isValidSSN(ssn: string, skipAgeCheck: boolean = false) {
  let strippedSsn = ssn.replaceAll(/\D/g, '');
  if (
    !isNumber(strippedSsn) ||
    (strippedSsn.length !== 10 && strippedSsn.length !== 12)
  ) {
    return false;
  }

  strippedSsn = prependCentury(strippedSsn);
  const lastNumber = Number(strippedSsn.slice(-1));

  if (strippedSsn.length === 10 || strippedSsn.length === 12) {
    if (!skipAgeCheck) {
      if (!isOlderThan(19)(strippedSsn)) return false;
      if (isOlderThan(70)(strippedSsn)) return false;
    }

    return luhn(strippedSsn.slice(-10, -1)) === lastNumber;
  }

  return false;
}
export const isValidPhoneNumber = phoneNumber => {
  const parsedNum = parsePhoneNumberFromString(phoneNumber, 'SE');
  return !!parsedNum && parsedNum.isValid();
};
export const isValidEmployerName = name => name.length > 0;

export const isValidMonthlySalary = (monthlySalary: number) =>
  isNumber(monthlySalary) && monthlySalary >= 10000 && monthlySalary <= 140000;

export const isValidEmail = email =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
    email,
  );

export const isValidSince = (since: string) => {
  const date = new Date();
  const currentYear: number = date.getFullYear();
  const currentMonth: number = date.getMonth() + 1;
  const result = since.match(/(\d{2})\/(\d{4})/);
  if (!result) return false;

  const month = parseInt(result[1], 10);
  const year = parseInt(result[2], 10);
  return (
    month &&
    year &&
    month >= 1 &&
    month <= 12 &&
    year >= currentYear - 55 &&
    (year < currentYear || (year === currentYear && month <= currentMonth))
  );
};

export const isValidTo = (to: string) => {
  const date = new Date();
  const currentYear: number = date.getFullYear();
  const currentMonth: number = date.getMonth() + 1;
  const result = to.match(/(\d{2})\/(\d{4})/);
  if (!result) return false;

  const month = parseInt(result[1], 10);
  const year = parseInt(result[2], 10);
  return (
    month &&
    year &&
    month >= 1 &&
    month <= 12 &&
    year <= currentYear + 55 &&
    (year > currentYear || (year === currentYear && year >= currentMonth))
  );
};

export const isValidAccountNumber = value => {
  value = stripNonDigits(value);
  // return bankAccountValidationPicker(value)
  return value.length > 8 && value.length <= 15;
};

export const isValidOccupation = value =>
  ['STUDENT', 'HOURLY', 'VACANCY', 'UNEMPLOYED'].indexOf(value) === -1 &&
  ['student', 'unemployed'].indexOf(value) === -1;

export function isValidAmount(amount) {
  if (!isNumber(amount)) return false;
  const amountValue = Number(amount);
  return amountValue >= 10000 && amountValue <= 300000;
}

export function isValidAmortizationPeriod(months) {
  if (!isNumber(months)) return false;
  const monthsValue = Number(months);
  return monthsValue >= 36 && monthsValue <= 144;
}
