import { combineReducers } from 'redux';

import loanApplicationSlice from './loanApplication';
import apiSlice from './api';
import accountCreditApplicationSlice from './accountCreditApplication';
import appSlice from './app';

export default combineReducers({
  loanApplication: loanApplicationSlice.reducer,
  api: apiSlice.reducer,
  accountCreditApplication: accountCreditApplicationSlice.reducer,
  app: appSlice.reducer
});
