import { capitalize } from '@lib/utils';

export const sliceReducerFromStateKeys = (state, createActionFromKey) =>
  Object.keys(state).reduce((acc, stateKey) => {
    const { name, actionCreator } = createActionFromKey(stateKey);
    return {
      ...acc,
      [name]: actionCreator,
    };
  }, {});

// Generates actions for updating the fields for all keys in state
export const generateUpdateReducers = state =>
  sliceReducerFromStateKeys(state, stateKey => ({
    name: `update${capitalize(stateKey)}`,
    actionCreator: (state, action) => updateField(state, action, stateKey),
  }));

// Generates actions for validating the fields for all keys in state
export const generateValidateReducers = (state, validators) =>
  sliceReducerFromStateKeys(state, stateKey => ({
    name: `validate${capitalize(stateKey)}`,
    actionCreator: (state, action) =>
      validateField(state, action, stateKey, validators),
  }));

const updateField = (state, action, stateKey) => {
  state[stateKey] = action.payload;
};

const validateField = (state, action, stateKey, validators) => {
  const validator = validators[stateKey];
  if (!validator) {
    console.warn('No validator found for stateKey', stateKey);
  }
  const isValid = !validator || validator(action.payload);
  state.isValid[stateKey] = isValid;
};
