import {
  isValidEmail,
  isValidEmployerName,
  isValidMonthlySalary,
  isValidOccupation,
  isValidPhoneNumber,
  isValidSince,
  isValidSSNExtended,
  isValidTo,
  isNumber,
  isValidSSN
} from '@src/lib/validationUtils';

import {
  generateUpdateReducers,
  generateValidateReducers,
} from '@src/redux/utils';

import { createSlice } from '@reduxjs/toolkit';

export type LoanApplicationReduxType = typeof initialState;

const initialState = {
  ssn: '', // TODO remove
  email: '',
  phone: '',
  occupation: null,
  appliedAmount: 150000,
  monthlySalary: null,
  accommodation: null,
  renegotiateLoans: false,
  renegotiateLoansAmount: 0,
  maritalStatus: null,
  hasChildren: false,
  numberOfChildren: undefined,
  since: '',
  to: '',
  companyPhone: '',
  amortizationPeriod: 120,
  loanPurpose: null,
  accommodationMonthlyCost: null,
  nameOfEmployer: '',
  withCoApplicant: false,
  coApplicantSsn: '',
  coApplicantEmail: '',
  coApplicantPhone: '',
  coApplicantSameAddress: false,
  coApplicantMaritalStatus: null,
  coApplicantHasChildren: false,
  coApplicantNumberOfChildren: undefined,
  coApplicantAccommodation: undefined,
  coApplicantAccommodationMonthlyCost: undefined,
  coApplicantMonthlySalary: null,
  coApplicantOccupation: null,
  coApplicantNameOfEmployer: '',
  coApplicantCompanyPhone: '',
  coApplicantOccupationSince: '',
  coApplicantOccupationTo: '',  
  isValid: {
    nameOfEmployer: false,
    accommodationMonthlyCost: false,
    isLaidOff: false,
    ssn: false,
    email: false,
    since: false,
    to: false,
    phone: false,
    occupation: false,
    appliedAmount: true,
    monthlySalary: false,
    accommodation: false,
    numberOfChildren: true,
    amortizationPeriod: true,
    loanPurpose: false,
    maritalStatus: false,
    companyPhone: false,
    coApplicantSsn: false,
    coApplicantEmail: false,
    coApplicantPhone: false,
    coApplicantMaritalStatus: false,
    coApplicantNumberOfChildren: false,
    coApplicantAccommodation: false,
    coApplicantAccommodationMonthlyCost: false,
    coApplicantMonthlySalary: false,
    coApplicantOccupation: false,
    coApplicantNameOfEmployer: false,
    coApplicantCompanyPhone: false,
    coApplicantOccupationSince: false,
    coApplicantOccupationTo: false,  
  },
};

const validators = {
  accommodationMonthlyCost: (m: number) => isNumber(m) && m,
  nameOfEmployer: isValidEmployerName,
  ssn: isValidSSNExtended,
  email: isValidEmail,
  phone: isValidPhoneNumber,
  companyPhone: isValidPhoneNumber,
  since: isValidSince,
  to: isValidTo,
  monthlySalary: isValidMonthlySalary,
  occupation: isValidOccupation,
  accommodation: x => Boolean(x),
  loanPurpose: purpose => !!purpose && purpose.length > 1,
  numberOfChildren: () => true,
  maritalStatus: x => Boolean(x),
  coApplicantSsn: ssn => isValidSSN(ssn),
  coApplicantAccommodationMonthlyCost: (m: number) => isNumber(m) && m,
  coApplicantNameOfEmployer: isValidEmployerName,
  coApplicantEmail: isValidEmail,
  coApplicantPhone: isValidPhoneNumber,
  coApplicantCompanyPhone: isValidPhoneNumber,
  coApplicantOccupationSince: isValidSince,
  coApplicantOccupationTo: isValidTo,
  coApplicantMonthlySalary: isValidMonthlySalary,
  coApplicantOccupation: isValidOccupation,
  coApplicantAccommodation: x => Boolean(x),
  coApplicantNumberOfChildren: () => true,
  coApplicantMaritalStatus: x => Boolean(x),
};

const reducers = {
  ...generateUpdateReducers(initialState),
  ...generateValidateReducers(initialState, validators)
};

const loanApplicationSlice = createSlice<LoanApplicationReduxType, any>({
  name: 'loanApplication',
  initialState,
  reducers,
});

export default loanApplicationSlice;
