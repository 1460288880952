//import { loadableReady } from '@loadable/component';
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/base.css';
import './src/styles/typography.css';
import './src/styles/variables.css';
import './src/styles/fonts.css';
import './src/styles/custom-media.css';

import './src/styles/globals.scss';

// ES5 way
// exports.onClientEntry = () => {
// ES6 way
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};

/*
export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback);
    });
  };
};
*/

import wrapWithProvider from './wrapWithProvider';
export const wrapRootElement = wrapWithProvider;

// https://github.com/gatsbyjs/gatsby/issues/7454#issuecomment-425403812
export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  // Dialogs append a ?dialog search param to the URL. When entering or leaving
  // it, it shouldn't scroll the window.
  if (location.search.includes('dialog')) {
    return false;
  }

  const helpRegex = /\/hjalp\/(\w+)(\/[^/]*)?/;

  if (location.pathname.match(helpRegex)) {
    return false;
  }

  if (prevRouterProps) {
    if (prevRouterProps.location.search.includes('dialog')) {
      return false;
    }
  }

  return getSavedScrollPosition(location);
};
