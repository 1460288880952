import { initializeApp } from 'firebase/app';
import 'firebase/firestore';

const config = {
  apiKey: process.env.GATSBY_FIREBASE_WEB_API_KEY,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
};

let firebaseInstance;
export const getFirebase = () => {
  if (typeof window === 'undefined') {
    // Server Side Rendering
    return null;
  }

  if (firebaseInstance) {
    return firebaseInstance;
  }

  firebaseInstance = initializeApp(config);

  return firebaseInstance;
};
