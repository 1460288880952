import React, { ReactNode } from 'react';
import { makeOperation } from '@urql/core';
import { devtoolsExchange } from '@urql/devtools';
import { authExchange } from '@urql/exchange-auth';

import { createClient, dedupExchange, fetchExchange, Provider } from 'urql';

import { tokenIsExpired } from '@src/lib/utils';
import { Dispatch } from 'redux';
import appSlice from '@src/redux/app';
import { connect } from 'react-redux';

interface Props {
  children: ReactNode;
  setLoggedOut: () => void;
}

const urqlClient = (setLoggedOut: () => void) =>
  createClient({
    url: process.env.GATSBY_GRAPHQL_BFF_ENDPOINT,
    exchanges: [
      devtoolsExchange,
      dedupExchange,
      authExchange<{ token?: string }>({
        willAuthError({ authState, operation }) {
          if (
            // Exclude auth mutation and query
            operation.kind === 'mutation' &&
            operation.query.definitions.some(definition => {
              return (
                definition.kind === 'OperationDefinition' &&
                definition.selectionSet.selections.some(node => {
                  return (
                    node.kind === 'Field' &&
                    (node.name.value === 'postAuth' ||
                      node.name.value === 'getAuth')
                  );
                })
              );
            })
          ) {
            return false;
          }

          if (!authState?.token) {
            return true;
          }
          if (tokenIsExpired(authState?.token)) {
            return true;
          }
          if (authState?.token !== localStorage.getItem('token')) {
            return true;
          }

          return false;
        },
        async getAuth({ authState }) {
          if (typeof window === 'undefined') return null;

          if (!authState) {
            const token = localStorage.getItem('token');
            if (token) {
              return { token };
            }
          }

          setLoggedOut();
          localStorage.removeItem('token');
          console.log('Clearing auth state');
          return null;
        },
        addAuthToOperation({ authState, operation }) {
          if (!authState || !authState.token) {
            return operation;
          }

          const fetchOptions =
            typeof operation.context.fetchOptions === 'function'
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {};

          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                'X-Authentication-Scheme': 'Web',
                authorization: `Bearer ${authState.token}`,
              },
            },
          });
        },
        didAuthError({ error }) {
          return error.graphQLErrors.some(
            e =>
              // @ts-ignore not typed well
              e?.response?.status === 401 ||
              e?.response?.status === 403 ||
              (e?.extensions?.code &&
                [401, 403, '401', '403', '500'].includes(e?.extensions?.code)), // FIXME 500 shouldn't really be here but unfortunately this is how BFF works :(
          );
        },
      }),
      fetchExchange,
    ],
  });

const UrqlProvider = ({ children, setLoggedOut }: Props) => (
  <Provider value={urqlClient(setLoggedOut)}>{children}</Provider>
);

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setLoggedOut: () => dispatch(appSlice.actions.setLoggedIn(false)),
  };
};

export default connect(null, mapDispatchToProps)(UrqlProvider);
